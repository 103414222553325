import { Toast, ToastContainer } from "react-bootstrap";
import { useSelector } from "react-redux";


export default function ToastMessages() {

	const messages = useSelector((state) => state.web3.messages);

	const Messages = messages.map(msg => 
		<Toast key={msg.id} bg={msg.variant || ''}>
		{/*<Toast.Header>
			<strong className="me-auto">Bootstrap</strong>
			<small className="text-muted">{msg.id}</small>
		</Toast.Header>*/}
		<Toast.Body style={msg.variant=='danger'?{color:'white'}:{}} dangerouslySetInnerHTML={{__html: msg.message}} ></Toast.Body>
	</Toast>
	);

	return (
		<ToastContainer position="bottom-start" containerPosition="fixed" >
			{Messages}
		</ToastContainer>
	)

}